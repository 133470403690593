@font-face {
  font-family: "Friends";
  font-style: normal;
  font-weight: normal;
  src: local("Friends-Regular"), url("./assets/fonts/friends-regular/font.woff2") format("woff2"), url("./assets/fonts/friends-regular/font.woff") format("woff");
}
@font-face {
  font-family: "Friends";
  font-style: italic;
  font-weight: normal;
  src: local("Friends-Italic"), url("./assets/fonts/friends-italic/font.woff2") format("woff2"), url("./assets/fonts/friends-italic/font.woff") format("woff");
}
@font-face {
  font-family: "Friends";
  font-style: normal;
  font-weight: bold;
  src: local("Friends-Bold"), url("./assets/fonts/friends-ultrabold/font.woff2") format("woff2"), url("./assets/fonts/friends-ultrabold/font.woff") format("woff");
}
body {
  margin: 0;
  font-family: "Friends" -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/*FluidFont*/
h1[class*=MuiTypography-h1] {
  line-height: 1.15;
}
h1[class*=MuiTypography-h1] {
  font-size: 36px;
}
@media screen and (min-width: 400px) {
  h1[class*=MuiTypography-h1] {
    font-size: calc(36px + 38 * ((100vw - 400px) / 1400));
  }
}
@media screen and (min-width: 1800px) {
  h1[class*=MuiTypography-h1] {
    font-size: 74px;
  }
}

*[class*=makeStyles-impressRoot] p[class*=MuiTypography-body2] {
  font-size: 18px;
}
@media screen and (min-width: 400px) {
  *[class*=makeStyles-impressRoot] p[class*=MuiTypography-body2] {
    font-size: calc(18px + 4 * ((100vw - 400px) / 1400));
  }
}
@media screen and (min-width: 1800px) {
  *[class*=makeStyles-impressRoot] p[class*=MuiTypography-body2] {
    font-size: 22px;
  }
}

h2[class*=MuiTypography-h2] {
  font-size: 32px;
}
@media screen and (min-width: 400px) {
  h2[class*=MuiTypography-h2] {
    font-size: calc(32px + 26 * ((100vw - 400px) / 1400));
  }
}
@media screen and (min-width: 1800px) {
  h2[class*=MuiTypography-h2] {
    font-size: 58px;
  }
}

h2[class*=MuiTypography-h2].animationTeaser {
  font-size: 28px;
}
@media screen and (min-width: 1024px) {
  h2[class*=MuiTypography-h2].animationTeaser {
    font-size: calc(28px + 30 * ((100vw - 1024px) / 776));
  }
}
@media screen and (min-width: 1800px) {
  h2[class*=MuiTypography-h2].animationTeaser {
    font-size: 58px;
  }
}

h3[class*=MuiTypography-h3] {
  margin-bottom: 0.5rem;
}
h3[class*=MuiTypography-h3] {
  font-size: 24px;
}
@media screen and (min-width: 400px) {
  h3[class*=MuiTypography-h3] {
    font-size: calc(24px + 10 * ((100vw - 400px) / 1400));
  }
}
@media screen and (min-width: 1800px) {
  h3[class*=MuiTypography-h3] {
    font-size: 34px;
  }
}

h3[class*=MuiTypography-h3][class*=headlineTile] {
  font-size: 24px;
}
@media screen and (min-width: 400px) {
  h3[class*=MuiTypography-h3][class*=headlineTile] {
    font-size: calc(24px + 28 * ((100vw - 400px) / 1400));
  }
}
@media screen and (min-width: 1800px) {
  h3[class*=MuiTypography-h3][class*=headlineTile] {
    font-size: 52px;
  }
}

h4[class*=MuiTypography-h4] {
  font-size: 20px;
}
@media screen and (min-width: 400px) {
  h4[class*=MuiTypography-h4] {
    font-size: calc(20px + 6 * ((100vw - 400px) / 1400));
  }
}
@media screen and (min-width: 1800px) {
  h4[class*=MuiTypography-h4] {
    font-size: 26px;
  }
}

h6[class*=MuiTypography-h6] {
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: #16e098;
}
h6[class*=MuiTypography-h6] {
  font-size: 14px;
}
@media screen and (min-width: 400px) {
  h6[class*=MuiTypography-h6] {
    font-size: calc(14px + 6 * ((100vw - 400px) / 1400));
  }
}
@media screen and (min-width: 1800px) {
  h6[class*=MuiTypography-h6] {
    font-size: 20px;
  }
}

.textCard {
  font-size: 16px;
}
@media screen and (min-width: 400px) {
  .textCard {
    font-size: calc(16px + 4 * ((100vw - 400px) / 1400));
  }
}
@media screen and (min-width: 1800px) {
  .textCard {
    font-size: 20px;
  }
}

div.firstLetter {
  font-size: 80px;
}
@media screen and (min-width: 400px) {
  div.firstLetter {
    font-size: calc(80px + 80 * ((100vw - 400px) / 1400));
  }
}
@media screen and (min-width: 1800px) {
  div.firstLetter {
    font-size: 160px;
  }
}

p[class*=MuiTypography-body2], th[class*=MuiTableCell-root] {
  line-height: 1.5;
}
p[class*=MuiTypography-body2], th[class*=MuiTableCell-root] {
  font-size: 16px;
}
@media screen and (min-width: 400px) {
  p[class*=MuiTypography-body2], th[class*=MuiTableCell-root] {
    font-size: calc(16px + 4 * ((100vw - 400px) / 1400));
  }
}
@media screen and (min-width: 1800px) {
  p[class*=MuiTypography-body2], th[class*=MuiTableCell-root] {
    font-size: 20px;
  }
}

p[class*=MuiTypography-body1] {
  line-height: 1.5;
}
p[class*=MuiTypography-body1] {
  font-size: 18px;
}
@media screen and (min-width: 400px) {
  p[class*=MuiTypography-body1] {
    font-size: calc(18px + 8 * ((100vw - 400px) / 1400));
  }
}
@media screen and (min-width: 1800px) {
  p[class*=MuiTypography-body1] {
    font-size: 26px;
  }
}

p[class*=MuiTypography-subtitle1] {
  opacity: 0.6;
  font-style: italic;
  line-height: 1.3;
}
p[class*=MuiTypography-subtitle1] {
  font-size: 16px;
}
@media screen and (min-width: 400px) {
  p[class*=MuiTypography-subtitle1] {
    font-size: calc(16px + 6 * ((100vw - 400px) / 1400));
  }
}
@media screen and (min-width: 1800px) {
  p[class*=MuiTypography-subtitle1] {
    font-size: 22px;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-slide {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 1;
  transition: all 0.5s !important;
}
.slick-slide.slick-active {
  opacity: 1 !important;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.returnBtn::after, .returnBtn::before {
  display: flex;
  align-items: center;
  justify-content: center;
  content: "Back to the Future";
  position: absolute;
  left: 10px;
  top: 0;
  width: 100%;
  height: 100%;
  ackground-color: white;
  -webkit-clip-path: inset(0px 0px 0px 0px);
          clip-path: inset(0px 0px 0px 0px);
  z-index: 2;
  opacity: 1;
  animation: noise-anim 2s infinite linear alternate-reverse;
}
.returnBtn::before {
  -webkit-transform: scale(1.1, 1.25) !important;
          transform: scale(1.1, 1.25) !important;
  border-width: 4px;
}

.returnBtn::after {
  z-index: 3;
  left: -2px;
  background-color: inherit;
  animation: noise-anim 1s infinite linear alternate-reverse;
}

@-webkit-keyframes noise-anim {
  0% {
    -webkit-clip-path: inset(40% 0 61% 0);
            clip-path: inset(40% 0 61% 0);
  }
  20% {
    -webkit-clip-path: inset(92% 0 1% 0);
            clip-path: inset(92% 0 1% 0);
  }
  40% {
    -webkit-clip-path: inset(43% 0 1% 0);
            clip-path: inset(43% 0 1% 0);
  }
  60% {
    -webkit-clip-path: inset(25% 0 58% 0);
            clip-path: inset(25% 0 58% 0);
  }
  80% {
    -webkit-clip-path: inset(54% 0 7% 0);
            clip-path: inset(54% 0 7% 0);
  }
  100% {
    -webkit-clip-path: inset(58% 0 80% 0);
            clip-path: inset(58% 0 80% 0);
  }
}

@keyframes noise-anim {
  0% {
    -webkit-clip-path: inset(40% 0 61% 0);
            clip-path: inset(40% 0 61% 0);
  }
  20% {
    -webkit-clip-path: inset(92% 0 1% 0);
            clip-path: inset(92% 0 1% 0);
  }
  40% {
    -webkit-clip-path: inset(43% 0 1% 0);
            clip-path: inset(43% 0 1% 0);
  }
  60% {
    -webkit-clip-path: inset(25% 0 58% 0);
            clip-path: inset(25% 0 58% 0);
  }
  80% {
    -webkit-clip-path: inset(54% 0 7% 0);
            clip-path: inset(54% 0 7% 0);
  }
  100% {
    -webkit-clip-path: inset(58% 0 80% 0);
            clip-path: inset(58% 0 80% 0);
  }
}
